import revive_payload_client_4sVQNw7RlN from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_izaWKZ8rEu from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_client_OVoKJro5pc from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/.nuxt/element-plus-injection.plugin.mjs";
import unocss_MzCDxu9LMj from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/.nuxt/unocss.mjs";
import ssr_plugin_B4ptqVdIfe from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import defaults_aRl2YLnTcb from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  plugin_client_OVoKJro5pc,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  unocss_MzCDxu9LMj,
  ssr_plugin_B4ptqVdIfe,
  defaults_aRl2YLnTcb
]